import React from 'react';

import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import MeshHeroPanel from '@nib-components/hero-panel';
import {
  copyColor,
  copyFontFamily,
  copyFontSize,
  copyLineHeight,
  headingColor,
  h3FontFamily,
  h3FontSize,
  h3FontWeight,
  h3Tracking,
  h3LineHeight,
} from '@nib-components/theme';
import Heading from '@nib-components/heading';

const Subtitle = styled.p`
  color: ${copyColor};
  font-family: ${copyFontFamily};
  font-size: ${copyFontSize};
  line-height: ${copyLineHeight};
  ${breakpoint('md')`
    color: ${headingColor};
    font-family: ${h3FontFamily};
    font-size: ${h3FontSize};
    font-weight: ${h3FontWeight};
    letter-spacing: ${h3Tracking};
    line-height: ${h3LineHeight};
  `};
`;
Subtitle.displayName = 'Subtitle';

/*
 * This is a temporary work around to make the hero panel subtitle smaller
 * when media size is narrow, i.e. mobile.
 * Laurie is going to be making an update to the condensed variant of the hero panel
 * mesh component so that the subtitle looks better on mobile.
 */
const HeroPanel = (props) => {
  const { images, subTitle, title, variation, children } = props;
  return (
    <MeshHeroPanel images={images} variation={variation}>
      {title && (
        <Heading component="p" size={{ xs: 2, md: 1 }} fontType="serif" color="trueGreen">
          {title}
        </Heading>
      )}
      {subTitle && <Subtitle>{subTitle}</Subtitle>}
      {children}
    </MeshHeroPanel>
  );
};

export default HeroPanel;
