import React from 'react';

import ReactPlayer from 'react-player/youtube';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px, py } from 'styled-components-spacing';

import { CommunityPartnersGraphicIcon } from '@nib/icons';

import { PrimaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';

import { Stack } from '@nib/layout';

export interface ContentfulMemberStoryNode {
  videoUrl: string;
  title: string;
  description: {
    childMarkdownRemark: {
      html: string;
    };
  };
}

const ShowButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem2'
    'gridItem1'
    'gridItem3';
  ${breakpoint('lg')`
    ${py(6)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'gridItem1 gridItem2'
      'gridItem1 gridItem3'
  `};
`;

const VideoOnRightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem2'
    'gridItem1';
  ${breakpoint('lg')`
  ${py(6)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'gridItem2 gridItem1'
`};
`;

const VideoOnLeftGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem2'
    'gridItem1';
  ${breakpoint('lg')`
    ${py(6)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'gridItem1 gridItem2'
  `};
`;

const GridItem1 = styled.div`
  grid-area: gridItem1;
  ${pb(4)};

  ${breakpoint('md')`
    ${pt(1)};
    ${pb(6)};
  `};

  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;
GridItem1.displayName = 'GridItem1';

const GridItem2 = styled.div`
  grid-area: gridItem2;
  ${pt(4)};
  ${breakpoint('lg')`
    ${px(4)};
  `};
`;
GridItem2.displayName = 'GridItem2';

const GridItem3 = styled.div`
  grid-area: gridItem3;
  text-align: center;
  ${breakpoint('lg')`
    text-align: left;
    ${px(4)};
    ${pb(4)};
  `};
`;
GridItem3.displayName = 'GridItem3';

const VideoWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  ${breakpoint('md')`
    min-height: 300px;
  `};

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
    ${px(4)};
  `};
`;

const ReactPlayerContainer = styled.div`
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
  ${breakpoint('lg')`
    position: static;
`};
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

interface MemberStoryProps {
  memberStory: ContentfulMemberStoryNode;
  showButton?: boolean;
  videoOnRight?: boolean;
}

const MemberStory = ({ memberStory, showButton, videoOnRight }: MemberStoryProps): JSX.Element => {
  let Grid;
  if (showButton === true) {
    Grid = ShowButtonGrid;
  } else if (videoOnRight === true) {
    Grid = VideoOnRightGrid;
  } else {
    Grid = VideoOnLeftGrid;
  }
  Grid.displayName = 'Grid';

  return (
    <Grid data-testid="member-story-grid">
      <GridItem1>
        <VideoWrapper role="video">
          <ReactPlayerContainer>
            <StyledReactPlayer
              url={memberStory.videoUrl}
              controls={true}
              height="100%"
              width="100%"
              data-testid="member-story-player"
            />
          </ReactPlayerContainer>
        </VideoWrapper>
      </GridItem1>
      <GridItem2>
        <Stack space={{ xs: 2, md: 4 }}>
          <CommunityPartnersGraphicIcon size="md" fill="trueGreen" data-testid="icon-community" />
          <Copy color="trueGreen">nib members</Copy>
          <Heading
            size={{ sm: 4, md: 3, lg: 2 }}
            color="trueGreen"
            component="p"
            data-testid="member-story-heading"
          >
            {memberStory.title}
          </Heading>
          <Copy
            component="div"
            dangerouslySetInnerHTML={{ __html: memberStory.description.childMarkdownRemark.html }}
            measure={false}
            data-testid="member-story-description"
          />
        </Stack>
      </GridItem2>
      {showButton === true && (
        <GridItem3>
          <PrimaryButton href="/member-stories" data-testid="more-stories-button">
            More member stories
          </PrimaryButton>
        </GridItem3>
      )}
    </Grid>
  );
};

export default MemberStory;
