import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { py, px } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { Section, Stack, Box } from '@nib/layout';
import Link from '@nib-components/link';
import FeaturePanel from '@nib-components/feature-panel';
import colors from '@nib-components/colors';
import { DocumentGraphicIcon, AssessmentGraphicIcon } from '@nib/icons';
import { colorTrueGreen } from '@nib-components/theme';

import desktop from '../img/using-cover/hero/desktop.jpg';
import desktop2x from '../img/using-cover/hero/desktop@2x.jpg';
import mobile from '../img/using-cover/hero/mobile.jpg';
import mobile2x from '../img/using-cover/hero/mobile@2x.jpg';
import tablet from '../img/using-cover/hero/tablet.jpg';
import tablet2x from '../img/using-cover/hero/tablet@2x.jpg';
import waitingPeriod from '../img/using-cover/waiting-period.jpg';

import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import UtilityButtons from '../components/UtilityButtons';
import metrics from '../metrics';
import MobileAppSection from '../components/MobileAppSection';
import { urlConstants } from '../constructs/constants';
import { ContentfulMemberStoryNode, UtilityLinkModel } from '../constructs/models';
import {
  Wrapper,
  ContentWrapper,
  ImageWrapper,
  Image,
  HeroPanelCopy,
} from '../components/styledComponents';
import MemberStory from '../components/MemberStory';

const FeaturePanelStyled = styled(FeaturePanel)`
  ${py(5)};
`;

const FeatureStyled = styled(FeaturePanel.Feature)`
  ${py(5)};
  background-color: ${colors.white};
  color: ${colorTrueGreen};
`;

const PaddedImageWrapper = styled(ImageWrapper)`
  ${breakpoint('lg')`
    ${px(4)};
  `};
`;

const MarginlessUl = styled.ul`
  margin-top: 0;
`;

const title = 'Using Your nib Health Cover';
const description =
  'How does your health cover work? Find out when you can start using your cover, and how to find out what’s covered by nib health insurance.';

const UsingCoverPage = () => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={description} />
    </Helmet>
    <HeroPanelSection />
    <WhereCanISeeMyCoverSection />
    <HowDoesMyCoverWorkSection />
    <NavigationSection />
    <WhenCanIStartSection />
    <MemberStorySection />
    <MobileAppSection />
  </Layout>
);

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
      title="Using your cover"
      variation="condensed"
      nextSectionBackgroundColor={colors.sneezy}
    >
      <HeroPanelCopy>Find out when and how you can use your health insurance.</HeroPanelCopy>
    </HeroPanel>
  );
};

const WhereCanISeeMyCoverSection = (): JSX.Element => {
  return (
    <Section role="section" background="sageGreen60">
      <Stack space={4}>
        <Box textAlign="center">
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Where can I see what I am covered for?
          </Heading>
        </Box>
        <Copy align="center" measure={false}>
          You can find out what your health insurance does and doesn’t cover by reading your Policy
          document and your latest Acceptance or Renewal certificate. You can find these on{' '}
          <Link href={urlConstants.mynib}>my nib</Link>.
        </Copy>
        <FeaturePanelStyled space={1}>
          <FeatureStyled
            icon={DocumentGraphicIcon}
            title="Your policy document tells you:"
            background="sageGreen60"
          >
            <Copy align="left" measure={false} color="darkest">
              <MarginlessUl>
                <li>What you are covered for</li>
                <li>What we don’t cover</li>
                <li>
                  Whether the nib prosthesis schedule applies to your policy. If it does, you can
                  find a copy on <Link href={urlConstants.mynib}>my nib</Link>
                </li>
                <li>Any other terms and conditions that are specific to your policy</li>
              </MarginlessUl>
            </Copy>
          </FeatureStyled>
          <FeatureStyled
            icon={AssessmentGraphicIcon}
            title="Your Acceptance or Renewal certificate tells you:"
          >
            <Copy align="left" measure={false} color="darkest">
              <MarginlessUl>
                <li>Who is covered by your policy</li>
                <li>
                  Any special conditions that apply (such as pre-existing conditions that are
                  excluded or loadings)
                </li>
                <li>How much your policy costs</li>
              </MarginlessUl>
            </Copy>
          </FeatureStyled>
        </FeaturePanelStyled>
      </Stack>
    </Section>
  );
};

const HowDoesMyCoverWorkSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={4}>
        <Box textAlign="center">
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
            How does my health cover work?
          </Heading>
        </Box>
        <Copy align="left" measure={false}>
          Everyday plans have a set of benefits that make up your health insurance cover.
        </Copy>
        <Copy align="left" measure={false}>
          Hospital plans may have two categories of benefits that make up your cover
        </Copy>
        <Copy align="left" measure={false}>
          <ul>
            <li>Base cover: a standard set of benefits that are included in your policy</li>
            <li>
              Options: benefits you can choose to add to your policy, such as non-PHARMAC, at an
              extra cost. Not all plans offer these Options.
            </li>
          </ul>
        </Copy>
        <Copy align="left" measure={false}>
          Each benefit has a limit to how much we will pay towards your claims within your policy
          year. We pay any approved costs for your treatment up to your available benefit limit,
          subject to any excess you pay and any exclusions that apply.
        </Copy>
        <Copy align="left" measure={false}>
          Most benefits renew every policy year and apply to every insured person covered by your
          policy.
        </Copy>
      </Stack>
    </Section>
  );
};

const NavigationSection = (): JSX.Element => {
  const utilityLinks: UtilityLinkModel[] = [
    {
      title: 'Find out now',
      subtitle: 'Are you covered?',
      url: '/am-i-covered',
    },
    {
      title: 'Find a Provider',
      subtitle: 'Need treatment?',
      url: urlConstants.findAProvider,
    },
    {
      title: 'Start now',
      subtitle: 'Making a claim?',
      url: '/claims',
    },
    {
      title: 'Log in',
      subtitle: 'View your cover:',
      url: urlConstants.mynib,
    },
  ];

  return (
    <Section role="section" background="warmWhite60">
      <UtilityButtons buttons={utilityLinks} />
    </Section>
  );
};

const WhenCanIStartSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Wrapper>
        <ContentWrapper>
          <Stack space={{ xs: 2, md: 4 }}>
            <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
              When can I start using my health cover?
            </Heading>
            <Copy measure={false}>
              Once you’ve joined nib, a waiting period may apply before you can make a claim. This
              will depend on details like the health insurance plan you have, the type of treatment
              you want to claim for and if you have recently upgraded your plan.
            </Copy>
            <Copy measure={false}>
              If you’d like to check if a waiting period applies to your cover and how long it is,
              just log in to <Link href={urlConstants.mynib}>my nib</Link> or the nib app to view
              your Policy document and latest Acceptance or Renewal Certificate.
            </Copy>
          </Stack>
        </ContentWrapper>
        <PaddedImageWrapper>
          <Image src={waitingPeriod} />
        </PaddedImageWrapper>
      </Wrapper>
    </Section>
  );
};

const MemberStorySection = (): JSX.Element => {
  const memberStory: ContentfulMemberStoryNode = {
    title: 'Jessica’s story:',
    description: {
      childMarkdownRemark: {
        html: `“My experience with claiming via the nib app is flawless.” The
        convenience of being able to claim on the go is perfect for Jessica’s
        busy and fast-paced lifestyle, making it quick and easy to take
        care of her health.`,
      },
    },
    videoUrl: 'https://www.youtube.com/embed/WNztT1bN9m8',
  };

  return (
    <Section role="section">
      <MemberStory memberStory={memberStory} showButton={false} />
    </Section>
  );
};

export default metrics({ pageName: 'using-cover' })(UsingCoverPage);
